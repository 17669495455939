/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #0a2540;
$primary: #06182c;
$secondary: #6c7c8c;
$ternary: #d9d9d9;
$border: #eaeaea;
$gray: #f4f6f9;
$accent: #0052ff;
