@import "default/color_variable";
@import "default/typography";
@import "common/general";
@import "common/slider";
@import "common/dark";

/**
  - Custom styles added to template
 */

.instagram-embed {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.instagram-embed-item {
  max-width: 320px;
  max-height: 700px;
}

@media (max-width: 767px) {
  .instagram-embed {
    flex-direction: column;
    iframe {
      width: 100%;
    }
  }
}
